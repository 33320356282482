<script setup lang="ts">
import Text from "~/components/atoms/Text.vue";

const calculatorFunnelStore = useCalculatorFunnelStore();
const calculatorData = computed(() => calculatorFunnelStore.getCalculatorData());
</script>

<template>
  <div v-if="calculatorData">
    <Text
      v-if="calculatorData.type === 'bmi'"
      class="tw-block tw-text-xs tw-leading-4"
      color="gray"
      translation-key="app.profunnel.calculator_funnel.bmi.disclaimer"
    ></Text>

    <Text
      v-if="calculatorData.type === 'idealweight'"
      class="tw-block tw-text-xs tw-leading-4"
      color="gray"
      translation-key="app.profunnel.calculator_funnel.ideal_weight.disclaimer"
    ></Text>
  </div>
</template>
