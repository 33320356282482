<script lang="ts" setup>
import { ref } from "vue";
import AppIcon from "~/components/app/AppIcon.vue";
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import type { SexScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams, Sex } from "~/types/onboarding/registrationParams";

interface Props {
  screen: SexScreen;
  registrationParams: RegistrationParams;
}

const props = defineProps<Props>();
const useTransparentBackground = props.screen.config?.useTransparentBackground;

const isModalShown = ref(false);
const showDescription = ref(!useTransparentBackground);
const showHelpLink = ref(!useTransparentBackground);

const { getNextScreenIdByGoal } = useConditionalNextSteps(props);

interface Emits {
  (
    e: "next",
    value: {
      params: { sex: Sex };
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

function selectOption(sex: Sex) {
  emit("next", {
    params: { sex },
    nextScreenId: getNextScreenIdByGoal(),
  });
}
</script>

<template>
  <div class="onboarding-content !tw-flex !tw-flex-col !tw-justify-between">
    <div :class="{ 'mt-5': useTransparentBackground }">
      <h1 class="text-header-md" :class="{ 'text-white !tw-text-center': useTransparentBackground }">
        {{ $t("registration.gender.headline") }}
      </h1>
      <p v-if="showDescription" class="mb-5">
        {{ $t("registration.gender.info.teaser") }}
      </p>
      <div class="row g-3">
        <div class="col-6">
          <OnboardingBtn
            emoji="🙋‍♀️"
            is-column
            translation-key="registration.gender.label.female"
            @click="selectOption('female')"
          />
        </div>
        <div class="col-6">
          <OnboardingBtn
            emoji="🙋‍♂️"
            is-column
            translation-key="registration.gender.label.male"
            @click="selectOption('male')"
          />
        </div>
      </div>
    </div>
    <div v-if="showHelpLink" class="!tw-text-center">
      <client-only>
        <button class="btn btn-link" type="button" @click="isModalShown = !isModalShown">
          <AppIcon icon="mdi:information" />
          {{ $t("registration.gender.info.modal.headline") }}
        </button>
        <BModal
          id="onboarding-sex-modal"
          v-model="isModalShown"
          ok-only
          :ok-title="$t('system.general.button.got_it')"
          ok-variant="primary"
          header-close-variant="link"
          header-close-class="text-body-tertiary !tw-p-0"
          header-class="!tw-pb-0 !tw-justify-end"
          body-class="!tw-text-center !tw-pt-0"
          modal-class="modal-md-bottom"
        >
          <template #header-close>
            <AppIcon icon="mdi:close-circle" width="2rem" />
          </template>
          <h2 class="mb-3">
            {{ $t("registration.gender.info.modal.headline") }}
          </h2>
          <p class="text-secondary !tw-mb-0">
            {{ $t("registration.gender.info.modal.description") }}
          </p>
        </BModal>
      </client-only>
    </div>
  </div>
</template>
