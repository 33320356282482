import type { Instructions } from "~/types/instructions";

export const onboardingCalculator: Instructions = {
  start: "main-goal",
  screens: [
    {
      type: "reason",
      id: "main-goal",
      eventName: "onboarding.encouraging_flow.goal.main_goal",
      titleTranslationKey: "onboarding.encouraging_flow.goal.main_goal.question",
      fallbackOption: {
        emoji: "💬",
        translationKey: "onboarding.encouraging_flow.goal.main_goal.other",
        trackingName: "onboarding.encouraging_flow.goal.main_goal.other",
        nextStep: "current-weight"
      },
      options: [
        {
          emoji: "\uD83D\uDCC9",
          translationKey: "onboarding.encouraging_flow.goal.main_goal.lose_weight",
          trackingName: "onboarding.encouraging_flow.goal.main_goal.lose_weight",
          nextStep: {
            condition: {
              type: "overall_goal",
              branch: "LooseWeight"
            },
            nextStep: "current-weight"
          }
        },
        {
          emoji: "\uD83D\uDC40",
          translationKey: "onboarding.encouraging_flow.goal.main_goal.maintain_weight",
          trackingName: "onboarding.encouraging_flow.goal.main_goal.maintain_weight",
          nextStep: {
            condition: {
              type: "overall_goal",
              branch: "MaintainWeight"
            },
            nextStep: "current-weight"
          }
        },
        {
          emoji: "\uD83D\uDCC8",
          translationKey: "onboarding.encouraging_flow.goal.main_goal.gain_weight",
          trackingName: "onboarding.encouraging_flow.goal.main_goal.gain_weight",
          nextStep: {
            condition: {
              type: "overall_goal",
              branch: "GainWeight"
            },
            nextStep: "current-weight"
          }
        },
        {
          emoji: "\uD83D\uDCAA",
          translationKey: "onboarding.encouraging_flow.goal.main_goal.build_muscle",
          trackingName: "onboarding.encouraging_flow.goal.main_goal.build_muscle",
          nextStep: {
            condition: {
              type: "overall_goal",
              branch: "BuildMuscle"
            },
            nextStep: "current-weight"
          }
        }
      ]
    },
    {
      type: "sex",
      nextStep: "current-weight",
      id: "gender",
      eventName: "onboarding.encouraging_flow.personal.gender",
      nextStepsWithCondition: []
    },
    {
      type: "current_weight",
      nextStep: "goal-weight",
      nextStepsWithCondition: [
        {
          nextStep: "goal-weight",
          condition: {
            type: "overall_goal",
            branch: "LooseWeight"
          }
        }
      ],
      id: "current-weight",
      eventName: "onboarding.encouraging_flow.personal.current_weight"
    },
    {
      type: "target_weight",
      nextStep: "height",
      nextStepsWithCondition: [
        {
          nextStep: "height",
          condition: {
            type: "overall_goal",
            branch: "LooseWeight"
          }
        }
      ],
      id: "goal-weight",
      eventName: "onboarding.encouraging_flow.personal_goal.goal_weight"
    },
    {
      type: "height",
      nextStep: "birthday",
      id: "height",
      eventName: "onboarding.encouraging_flow.personal.height"
    },
    {
      type: "birthday",
      nextStep: "load-plan",
      id: "birthday",
      eventName: "onboarding.encouraging_flow.personal.birthday"
    },
    {
      type: "loading_personal_plan",
      nextStep: "end",
      id: "load-plan",
      eventName: "onboarding.encouraging_flow.plan.load_plan_1"
    }
  ]
}
