<script lang="ts" setup>
import AppBackgroundImage from "~/components/app/AppBackgroundImage.vue";
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import type { AffirmationScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";

interface Props {
  screen: AffirmationScreen;
  hasWideWrapper: boolean;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

function next() {
  emit("next", {
    params: {},
    nextScreenId: props.screen.nextStep,
  });
}
</script>

<template>
  <OnboardingStickyButtonWrapper
    :button-disabled="false"
    :button-text="$t(screen.nextButtonTranslationKey)"
    :has-wide-wrapper="props.hasWideWrapper"
    @button-click="next"
  >
    <AppBackgroundImage :src="screen.imageUrl" :size="screen.imageSize" />
    <div class="container !tw-flex !tw-justify-center">
      <div class="col-lg-7 pt-4">
        <h1 class="text-header-md">
          {{ $t(screen.titleTranslationKey) }}
        </h1>
        <div>{{ $t(screen.captionTranslationKey) }}</div>
      </div>
    </div>
  </OnboardingStickyButtonWrapper>
</template>
