<script lang="ts" setup>
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import type { Instructions } from "~/types/instructions";
import OnboardingWrapper from "~/components/onboarding/OnboardingWrapper.vue";
import OnboardingWrapperWide from "~/components/onboarding/OnboardingWrapperWide.vue";

import { onboardingPartner } from "~/resources/onboarding/onboarding-partner";
import { onboardingShort } from "~/resources/onboarding/onboarding-short";
import { onboardingTest } from "~/resources/onboarding/onboarding-test";
import { onboardingWelcome } from "~/resources/onboarding/onboarding-welcome";
import { onboardingCalculator } from "~/resources/onboarding/onboarding-calculator";
import { useOnboardingStore } from "~/stores/useOnboardingStore";

const { enabledFunnels } = useAppConfig();
const { t } = useNuxtApp().$i18n;

useHead({
  title: t("app.prosignup.seo_title"),
});

definePageMeta({
  key: "onboarding",
  middleware: ["redirect-authenticated-users"],
});

const defaultInstructionsIdentifier = "start";
const onboardingStore = useOnboardingStore();
const route = useRoute();

const { addImpressionEvent } = useTrackingStore();
const { skippedOnboarding } = storeToRefs(onboardingStore);

const {
  updateRegistrationParams,
  screen,
  screenComponent,
  registrationParams,
  screenHasWideWrapper,
  screenUseTransparentBackground,
  getProgress,
  checkForLoadingScreen,
} = useOnboarding(await getInstructions());

const wrapperComponent = computed(() => (screenHasWideWrapper.value ? OnboardingWrapperWide : OnboardingWrapper));

async function getInstructions() {
  const funnels: Record<string, Instructions> = {
    partner: onboardingPartner,
    short: onboardingShort,
    test: onboardingTest,
    welcome: onboardingWelcome,
    calculator: onboardingCalculator,
  };

  if (!route.params.instructions || route.params.instructions === defaultInstructionsIdentifier) {
    return funnels.short;
  }

  const requestedFunnel: string = Array.isArray(route.params.instructions)
    ? route.params.instructions[0]
    : route.params.instructions;

  if (!enabledFunnels.includes(requestedFunnel) || !funnels[requestedFunnel]) {
    await navigateTo("/onboarding");
    return funnels.short;
  }

  return funnels[requestedFunnel];
}

async function doNext({ nextScreenId, params }: { nextScreenId: string; params: Partial<RegistrationParams> }) {
  updateRegistrationParams(params);

  if (nextScreenId === "end") {
    updateRegistrationParams({ incomplete: false });

    return navigateTo({
      path: "/onboarding/checkout",
      query: {
        instructions: route.params.instructions || defaultInstructionsIdentifier,
      },
    });
  }

  if (checkForLoadingScreen(nextScreenId)) {
    return;
  }

  await navigateTo(`/onboarding/${route.params.instructions || defaultInstructionsIdentifier}/${nextScreenId}`);
}

function trackScreenImpression() {
  addImpressionEvent({ name: screen.value.eventName });
}

onMounted(() => {
  skippedOnboarding.value = false;
  trackScreenImpression();
});

watch(screen, () => {
  trackScreenImpression();
});
</script>

<template>
  <transition name="fade" mode="out-in">
    <component
      :is="wrapperComponent"
      :progress="getProgress()"
      :screen-component="screenComponent"
      :screen="screen"
      :registration-params="registrationParams"
      :has-wide-wrapper="screenHasWideWrapper ?? undefined"
      :use-transparent-background="screenUseTransparentBackground"
      @next="doNext"
    />
  </transition>
</template>
