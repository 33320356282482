<script lang="ts" setup>
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import type { InfoListImageScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import OnboardingStylingChanges from "~/components/onboarding/utils/OnboardingStylingChanges.vue";

const config = useRuntimeConfig();
const { locale } = useI18n();

interface Props {
  screen: InfoListImageScreen;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

const imageUrl = computed(() => {
  const width = 1920;
  return `${config.public.imageBaseUrl}/${props.screen.imageUrl}.${locale.value}.light.png?w=${width}`;
});

const transformImageUrl = (url: string) => {
  return `${config.public.imageBaseUrl}/${url}.${locale.value}.light.png?w=500`;
};

function next() {
  emit("next", {
    params: {},
    nextScreenId: props.screen.nextStep,
  });
}
</script>

<template>
  <OnboardingStickyButtonWrapper :button-text="$t(screen.nextButtonTranslationKey)" @button-click="next">
    <OnboardingStylingChanges hide-progress></OnboardingStylingChanges>
    <div class="tw-mb-5 tw-flex tw-flex-col tw-gap-5">
      <h1 class="text-header-md">
        {{ $t(screen.titleTranslationKey) }}
      </h1>
      <img :src="imageUrl" alt="" class="tw-max-w-full" />
      <div class="tw-flex tw-flex-col tw-gap-10">
        <div v-for="(option, key) in screen.infoList" :key="key" class="tw-flex tw-items-center tw-gap-5">
          <span v-if="option.emoji" class="tw-rounded-full tw-bg-gray-200 tw-p-3">{{ option.emoji }}</span>
          <div v-else-if="option.imageUrl">
            <img v-if="option.imageUrl === 'columbia'" src="~/assets/images/columbia.svg" alt="" class="tw-w-[7rem]" />
            <img v-else-if="option.imageUrl === 'aasd'" src="~/assets/images/aasd.png" alt="" class="tw-w-[7rem]" />
            <img v-else :src="transformImageUrl(option.imageUrl)" alt="" class="tw-w-[7rem]" />
          </div>
          <div>{{ $t(option.translationKey) }}</div>
        </div>
      </div>
    </div>
  </OnboardingStickyButtonWrapper>
</template>
