import { invert } from "lodash-es";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import { goalMap } from "~/types/onboarding/onboardingGoal";
import type { GoalType } from "~/types/onboarding/onboardingGoal";
import type {
  CurrentWeightScreen,
  IllustrationsRecipesScreen,
  SexScreen,
  TargetWeightScreen,
} from "~/types/onboarding/onboardingScreen";

interface Props {
  registrationParams: Partial<RegistrationParams>;
  screen: TargetWeightScreen | CurrentWeightScreen | IllustrationsRecipesScreen | SexScreen;
}

export function useConditionalNextSteps(props: Props) {
  function getNextScreenIdByGoal() {
    const goalAbbr = props.registrationParams.reason;
    const conditionalNextSteps =
      props.screen.type === "illustrations_recipes"
        ? props.screen.conditionalNextSteps
        : props.screen.nextStepsWithCondition;

    if (goalAbbr) {
      const reason = invert(goalMap)[goalAbbr] as GoalType;
      const nextStep = conditionalNextSteps.find((item) => item.condition.branch === reason);
      if (nextStep) {
        return nextStep.nextStep;
      }
    }

    if (!goalAbbr) {
      const nextStep = conditionalNextSteps.find((item) => item.condition.branch === null);
      if (nextStep) {
        return nextStep.nextStep;
      }
    }

    return props.screen.nextStep;
  }

  return {
    getNextScreenIdByGoal,
  };
}
