import type { Instructions } from "~/types/instructions";

const personalIdentification: Instructions['screens'] = [
  {
    type: "age_range",
    nextStep: "gender",
    id: "age-range",
    eventName: "onboarding.encouraging_flow.personal.age_range",
  },
  {
    type: "sex",
    nextStep: "main-goal",
    id: "gender",
    eventName: "onboarding.encouraging_flow.personal.gender",
    nextStepsWithCondition: []
  },
  {
    type: "reason",
    id: "main-goal",
    eventName: "onboarding.encouraging_flow.goal.main_goal",
    titleTranslationKey: "onboarding.encouraging_flow.goal.main_goal.question",
    fallbackOption: {
      emoji: "💬",
      translationKey: "onboarding.encouraging_flow.goal.main_goal.other",
      trackingName: "onboarding.encouraging_flow.goal.main_goal.other",
      nextStep: "current-weight"
    },
    options: [
      {
        emoji: "\uD83D\uDCC9",
        translationKey: "onboarding.encouraging_flow.goal.main_goal.lose_weight",
        trackingName: "onboarding.encouraging_flow.goal.main_goal.lose_weight",
        nextStep: {
          condition: {
            type: "overall_goal",
            branch: "LooseWeight"
          },
          nextStep: "reason-lose"
        }
      },
      {
        emoji: "\uD83D\uDC40",
        translationKey: "onboarding.encouraging_flow.goal.main_goal.maintain_weight",
        trackingName: "onboarding.encouraging_flow.goal.main_goal.maintain_weight",
        nextStep: {
          condition: {
            type: "overall_goal",
            branch: "MaintainWeight"
          },
          nextStep: "current-weight"
        }
      },
      {
        emoji: "\uD83D\uDCC8",
        translationKey: "onboarding.encouraging_flow.goal.main_goal.gain_weight",
        trackingName: "onboarding.encouraging_flow.goal.main_goal.gain_weight",
        nextStep: {
          condition: {
            type: "overall_goal",
            branch: "GainWeight"
          },
          nextStep: "reason-gain"
        }
      },
      {
        emoji: "\uD83D\uDCAA",
        translationKey: "onboarding.encouraging_flow.goal.main_goal.build_muscle",
        trackingName: "onboarding.encouraging_flow.goal.main_goal.build_muscle",
        nextStep: {
          condition: {
            type: "overall_goal",
            branch: "BuildMuscle"
          },
          nextStep: "reason-build"
        }
      }
    ]
  },
];

const motivationLose: Instructions['screens'] = [
  {
    type: "single_choice",
    id: "reason-lose",
    eventName: "onboarding.encouraging_flow.goal.reason",
    titleTranslationKey: "onboarding.encouraging_flow.goal.reason.question",
    options: [
      {
        emoji: "😎",
        translationKey: "onboarding.encouraging_flow.goal.reason.confidence",
        trackingName: "onboarding.encouraging_flow.goal.reason.confidence",
        nextStep: "additional-goal-lose"
      },
      {
        emoji: "💚",
        translationKey: "onboarding.encouraging_flow.goal.reason.health",
        trackingName: "onboarding.encouraging_flow.goal.reason.health",
        nextStep: "additional-goal-lose"
      },
      {
        emoji: "🏋️",
        translationKey: "onboarding.encouraging_flow.goal.reason.fitness",
        trackingName: "onboarding.encouraging_flow.goal.reason.fitness",
        nextStep: "additional-goal-lose"
      },
      {
        emoji: "🎉",
        translationKey: "onboarding.encouraging_flow.goal.reason.event",
        trackingName: "onboarding.encouraging_flow.goal.reason.event",
        nextStep: "additional-goal-lose"
      },
      {
        emoji: "💬",
        translationKey: "onboarding.encouraging_flow.goal.reason.other",
        trackingName: "onboarding.encouraging_flow.goal.reason.other",
        nextStep: "additional-goal-lose"
      }
    ]
  },
  {
    type: "multi_choice",
    id: "additional-goal-lose",
    eventName: "onboarding.encouraging_flow.goal.additional_goal",
    titleTranslationKey: "onboarding.encouraging_flow.goal.additional_goal.question",
    options: [
      {
        emoji: "🍲",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.food",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.food"
      },
      {
        emoji: "🧑‍🍳",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.cooking",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.cooking"
      },
      {
        emoji: "🍋",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.immune_system",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.immune_system"
      },
      {
        emoji: "💤",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.sleep",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.sleep"
      },
      {
        emoji: "🫶",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.feel_good",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.feel_good"
      },
      {
        emoji: "💬",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.other",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.other"
      }
    ],
    nextButtonTranslationKey: "system.general.button.next",
    nextStep: "past-experience-lose"
  },
  {
    type: "single_choice",
    id: "past-experience-lose",
    eventName: "onboarding.encouraging_flow.past.experience",
    titleTranslationKey: "onboarding.encouraging_flow.past.experience.question",
    options: [
      {
        emoji: "↘️",
        translationKey: "onboarding.encouraging_flow.past.experience.successful",
        trackingName: "onboarding.encouraging_flow.past.experience.successful",
        nextStep: "diets-restrictive"
      },
      {
        emoji: "➡️",
        translationKey: "onboarding.encouraging_flow.past.experience.unsuccessful",
        trackingName: "onboarding.encouraging_flow.past.experience.unsuccessful",
        nextStep: "diets-restrictive"
      },
      {
        emoji: "↔️",
        translationKey: "onboarding.encouraging_flow.past.experience.gained_back",
        trackingName: "onboarding.encouraging_flow.past.experience.gained_back",
        nextStep: "diets-restrictive"
      },
      {
        emoji: "💡",
        translationKey: "onboarding.encouraging_flow.past.experience.first_attempt",
        trackingName: "onboarding.encouraging_flow.past.experience.first_attempt",
        nextStep: "changes-differences"
      },
    ]
  },
  {
    type: "info_list_image",
    id: "diets-restrictive",
    eventName: "onboarding.encouraging_flow.diets.restrictive",
    titleTranslationKey: "onboarding.encouraging_flow.diets.restrictive.title",
    imageUrl: "app/misc/illustrations/onboarding/onboarding.encouraging_flow.yo-yo-effect",
    infoList: [
      {
        translationKey: "onboarding.encouraging_flow.yo_yo_effect.reference.columbia_university",
        imageUrl: "columbia"
      },
      {
        translationKey: "onboarding.encouraging_flow.yo_yo_effect.reference.asian_association",
        imageUrl: "aasd"
      }
    ],
    nextButtonTranslationKey: "system.general.button.continue",
    nextStep: "diets-sustainable"
  },
  {
    type: "info_list_image",
    id: "diets-sustainable",
    eventName: "onboarding.encouraging_flow.diets.sustainable",
    titleTranslationKey: "onboarding.encouraging_flow.diets.sustainable.title",
    imageUrl: "app/misc/illustrations/onboarding/onboarding.encouraging_flow.yazio-vs-restrictive-diets",
    infoList: [
      {
        translationKey: "onboarding.encouraging_flow.diets.sustainable.affirmation_no_rules",
        emoji: "🙌"
      },
      {
        translationKey: "onboarding.encouraging_flow.diets.sustainable.affirmation_weight_loss",
        emoji: "🍏"
      }
    ],
    nextButtonTranslationKey: "system.general.button.continue",
    nextStep: "challenges-lose"
  },
  {
    type: "multi_choice",
    id: "challenges-lose",
    eventName: "onboarding.encouraging_flow.past.challenges",
    titleTranslationKey: "onboarding.encouraging_flow.past.challenges.question",
    options: [
      {
        emoji: "🍟",
        translationKey: "onboarding.encouraging_flow.past.challenges.cravings",
        trackingName: "onboarding.encouraging_flow.past.challenges.cravings"
      },
      {
        emoji: "✨",
        translationKey: "onboarding.encouraging_flow.past.challenges.motivation",
        trackingName: "onboarding.encouraging_flow.past.challenges.motivation"
      },
      {
        emoji: "🥣",
        translationKey: "onboarding.encouraging_flow.past.challenges.portions",
        trackingName: "onboarding.encouraging_flow.past.challenges.portions"
      },
      {
        emoji: "🥗",
        translationKey: "onboarding.encouraging_flow.past.challenges.food",
        trackingName: "onboarding.encouraging_flow.past.challenges.food"
      },
      {
        emoji: "⏰",
        translationKey: "onboarding.encouraging_flow.past.challenges.busy",
        trackingName: "onboarding.encouraging_flow.past.challenges.busy"
      },
      {
        emoji: "💬",
        translationKey: "onboarding.encouraging_flow.past.challenges.other",
        trackingName: "onboarding.encouraging_flow.past.challenges.other"
      }
    ],
    nextButtonTranslationKey: "system.general.button.next",
    nextStep: "challenges-affirmation-lose"
  },
  {
    type: "affirmation",
    id: "challenges-affirmation-lose",
    eventName: "onboarding.encouraging_flow.past.challenges_affirmation",
    titleTranslationKey: "onboarding.encouraging_flow.past.challenges_affirmation.title",
    captionTranslationKey: "onboarding.encouraging_flow.past.challenges_affirmation.description",
    imageSize: "LARGE",
    imageUrl: "app/misc/illustrations/welcome_greetings",
    nextButtonTranslationKey: "system.general.button.lets_go",
    nextStep: "gain-causes-lose"
  },
  {
    type: "single_choice",
    id: "gain-causes-lose",
    eventName: "onboarding.encouraging_flow.past.gain_causes",
    titleTranslationKey: "onboarding.encouraging_flow.past.gain_causes.question",
    options: [
      {
        emoji: "🩹",
        translationKey: "onboarding.encouraging_flow.past.gain_causes.injury",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.injury",
        nextStep: "changes-lose"
      },
      {
        emoji: "💼",
        translationKey: "onboarding.encouraging_flow.past.gain_causes.personal_life",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.personal_life",
        nextStep: "changes-lose"
      },
      {
        emoji: "🔄",
        translationKey: "onboarding.encouraging_flow.past.gain_causes.metabolism",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.metabolism",
        nextStep: "changes-lose"
      },
      {
        emoji: "🤯",
        translationKey: "onboarding.encouraging_flow.past.gain_causes.mental_health",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.mental_health",
        nextStep: "changes-lose"
      },
      {
        emoji: "💊",
        translationKey: "onboarding.encouraging_flow.past.gain_causes.medication",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.medication",
        nextStep: "changes-lose"
      },
      {
        emoji: "💬",
        translationKey: "onboarding.encouraging_flow.past.gain_causes.other",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.other",
        nextStep: "changes-lose"
      }
    ]
  },
  {
    type: "single_choice",
    id: "changes-lose",
    eventName: "onboarding.encouraging_flow.past.changes",
    titleTranslationKey: "onboarding.encouraging_flow.past.changes.question",
    optionsLayout: "Grid",
    options: [
      {
        emoji: "👍",
        translationKey: "system.general.button.yes",
        trackingName: "onboarding.encouraging_flow.past.changes.yes",
        nextStep: "changes-differences"
      },
      {
        emoji: "👎",
        translationKey: "system.general.button.no",
        trackingName: "onboarding.encouraging_flow.past.changes.no",
        nextStep: "current-weight"
      },
    ]
  },
];

const motivationGain: Instructions['screens'] = [
  {
    type: "single_choice",
    id: "reason-gain",
    eventName: "onboarding.encouraging_flow.gain_weight.reason",
    titleTranslationKey: "onboarding.encouraging_flow.gain_weight.goal.reason.question",
    options: [
      {
        emoji: "😎",
        translationKey: "onboarding.encouraging_flow.goal.reason.confidence",
        trackingName: "onboarding.encouraging_flow.goal.reason.confidence",
        nextStep: "additional-goal-gain"
      },
      {
        emoji: "💚",
        translationKey: "onboarding.encouraging_flow.goal.reason.health",
        trackingName: "onboarding.encouraging_flow.goal.reason.health",
        nextStep: "additional-goal-gain"
      },
      {
        emoji: "🏋️",
        translationKey: "onboarding.encouraging_flow.goal.reason.fitness",
        trackingName: "onboarding.encouraging_flow.goal.reason.fitness",
        nextStep: "additional-goal-gain"
      },
      {
        emoji: "🎉",
        translationKey: "onboarding.encouraging_flow.goal.reason.event",
        trackingName: "onboarding.encouraging_flow.goal.reason.event",
        nextStep: "additional-goal-gain"
      },
      {
        emoji: "💬",
        translationKey: "onboarding.encouraging_flow.goal.reason.other",
        trackingName: "onboarding.encouraging_flow.goal.reason.other",
        nextStep: "additional-goal-gain"
      }
    ]
  },
  {
    type: "multi_choice",
    id: "additional-goal-gain",
    eventName: "onboarding.encouraging_flow.goal.additional_goal",
    titleTranslationKey: "onboarding.encouraging_flow.goal.additional_goal.question",
    options: [
      {
        emoji: "🍲",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.food",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.food"
      },
      {
        emoji: "🧑‍🍳",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.cooking",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.cooking"
      },
      {
        emoji: "🍋",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.immune_system",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.immune_system"
      },
      {
        emoji: "💤",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.sleep",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.sleep"
      },
      {
        emoji: "🫶",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.feel_good",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.feel_good"
      },
      {
        emoji: "💬",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.other",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.other"
      }
    ],
    nextButtonTranslationKey: "system.general.button.next",
    nextStep: "past-experience-gain"
  },
  {
    type: "single_choice",
    id: "past-experience-gain",
    eventName: "onboarding.encouraging_flow.gain_weight.past.experience",
    titleTranslationKey: "onboarding.encouraging_flow.gain_weight.past.experience.question",
    options: [
      {
        emoji: "↗️",
        translationKey: "onboarding.encouraging_flow.gain_weight.past.experience.successful",
        trackingName: "onboarding.encouraging_flow.gain_weight.past.experience.successful",
        nextStep: "challenges-gain"
      },
      {
        emoji: "➡️",
        translationKey: "onboarding.encouraging_flow.gain_weight.past.experience.unsuccessful",
        trackingName: "onboarding.encouraging_flow.gain_weight.past.experience.unsuccessful",
        nextStep: "challenges-gain"
      },
      {
        emoji: "↔️",
        translationKey: "onboarding.encouraging_flow.gain_weight.past.experience.lost_again",
        trackingName: "onboarding.encouraging_flow.gain_weight.past.experience.lost_again",
        nextStep: "challenges-gain"
      },
      {
        emoji: "💡",
        translationKey: "onboarding.encouraging_flow.gain_weight.past.experience.first_attempt",
        trackingName: "onboarding.encouraging_flow.gain_weight.past.experience.first_attempt",
        nextStep: "current-weight"
      },
    ]
  },
  {
    type: "multi_choice",
    id: "challenges-gain",
    eventName: "onboarding.encouraging_flow.past.challenges",
    titleTranslationKey: "onboarding.encouraging_flow.past.challenges.question",
    options: [
      {
        emoji: "📈",
        translationKey: "onboarding.encouraging_flow.gain_weight.past.challenges.replenish",
        trackingName: "onboarding.encouraging_flow.gain_weight.past.challenges.replenish"
      },
      {
        emoji: "✨",
        translationKey: "onboarding.encouraging_flow.past.challenges.motivation",
        trackingName: "onboarding.encouraging_flow.past.challenges.motivation"
      },
      {
        emoji: "🥣",
        translationKey: "onboarding.encouraging_flow.past.challenges.quality_foods",
        trackingName: "onboarding.encouraging_flow.past.challenges.quality_foods"
      },
      {
        emoji: "🥗",
        translationKey: "onboarding.encouraging_flow.past.challenges.food",
        trackingName: "onboarding.encouraging_flow.past.challenges.food"
      },
      {
        emoji: "⏰",
        translationKey: "onboarding.encouraging_flow.past.challenges.busy",
        trackingName: "onboarding.encouraging_flow.past.challenges.busy"
      },
      {
        emoji: "💬",
        translationKey: "onboarding.encouraging_flow.past.challenges.other",
        trackingName: "onboarding.encouraging_flow.past.challenges.other"
      }
    ],
    nextButtonTranslationKey: "system.general.button.next",
    nextStep: "challenges-affirmation-gain"
  },
  {
    type: "affirmation",
    id: "challenges-affirmation-gain",
    eventName: "onboarding.encouraging_flow.past.challenges_affirmation",
    titleTranslationKey: "onboarding.encouraging_flow.past.challenges_affirmation.title",
    captionTranslationKey: "onboarding.encouraging_flow.gain_weight.past.challenges_affirmation.description",
    imageSize: "LARGE",
    imageUrl: "app/misc/illustrations/welcome_greetings",
    nextButtonTranslationKey: "system.general.button.lets_go",
    nextStep: "gain-causes-gain"
  },
  {
    type: "single_choice",
    id: "gain-causes-gain",
    eventName: "onboarding.encouraging_flow.gain_weight.past.gain_causes",
    titleTranslationKey: "onboarding.encouraging_flow.gain_weight.past.gain_causes.question",
    options: [
      {
        emoji: "🩹",
        translationKey: "onboarding.encouraging_flow.past.gain_causes.injury",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.injury",
        nextStep: "changes-gain"
      },
      {
        emoji: "💼",
        translationKey: "onboarding.encouraging_flow.past.gain_causes.personal_life",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.personal_life",
        nextStep: "changes-gain"
      },
      {
        emoji: "🔄",
        translationKey: "onboarding.encouraging_flow.gain_weight.past.gain_causes.metabolism",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.metabolism",
        nextStep: "changes-gain"
      },
      {
        emoji: "🤯",
        translationKey: "onboarding.encouraging_flow.past.gain_causes.mental_health",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.mental_health",
        nextStep: "changes-gain"
      },
      {
        emoji: "💊",
        translationKey: "onboarding.encouraging_flow.past.gain_causes.medication",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.medication",
        nextStep: "changes-gain"
      },
      {
        emoji: "💬",
        translationKey: "onboarding.encouraging_flow.past.gain_causes.other",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.other",
        nextStep: "changes-gain"
      }
    ]
  },
  {
    type: "single_choice",
    id: "changes-gain",
    eventName: "onboarding.encouraging_flow.gain_weight.past.changes",
    titleTranslationKey: "onboarding.encouraging_flow.gain_weight.past.changes.question",
    optionsLayout: "Grid",
    options: [
      {
        emoji: "👍",
        translationKey: "system.general.button.yes",
        trackingName: "onboarding.encouraging_flow.past.changes.yes",
        nextStep: "changes-differences"
      },
      {
        emoji: "👎",
        translationKey: "system.general.button.no",
        trackingName: "onboarding.encouraging_flow.past.changes.no",
        nextStep: "current-weight"
      },
    ]
  },
];

const motivationBuildMuscle: Instructions['screens'] = [
  {
    type: "single_choice",
    id: "reason-build",
    eventName: "onboarding.encouraging_flow.build_muscle.goal.reason",
    titleTranslationKey: "onboarding.encouraging_flow.build_muscle.goal.reason.question",
    options: [
      {
        emoji: "😎",
        translationKey: "onboarding.encouraging_flow.goal.reason.confidence",
        trackingName: "onboarding.encouraging_flow.goal.reason.confidence",
        nextStep: "additional-goal-build"
      },
      {
        emoji: "💚",
        translationKey: "onboarding.encouraging_flow.goal.reason.health",
        trackingName: "onboarding.encouraging_flow.goal.reason.health",
        nextStep: "additional-goal-build"
      },
      {
        emoji: "🏋️",
        translationKey: "onboarding.encouraging_flow.goal.reason.fitness",
        trackingName: "onboarding.encouraging_flow.goal.reason.fitness",
        nextStep: "additional-goal-build"
      },
      {
        emoji: "🎉",
        translationKey: "onboarding.encouraging_flow.goal.reason.event",
        trackingName: "onboarding.encouraging_flow.goal.reason.event",
        nextStep: "additional-goal-build"
      },
      {
        emoji: "🔥",
        translationKey: "onboarding.encouraging_flow.build_muscle.goal.reason.burn_calories",
        trackingName: "onboarding.encouraging_flow.goal.reason.burn_calories",
        nextStep: "additional-goal-build"
      },
      {
        emoji: "💬",
        translationKey: "onboarding.encouraging_flow.goal.reason.other",
        trackingName: "onboarding.encouraging_flow.goal.reason.other",
        nextStep: "additional-goal-build"
      }
    ]
  },
  {
    type: "multi_choice",
    id: "additional-goal-build",
    eventName: "onboarding.encouraging_flow.goal.additional_goal",
    titleTranslationKey: "onboarding.encouraging_flow.goal.additional_goal.question",
    options: [
      {
        emoji: "🍲",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.food",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.food"
      },
      {
        emoji: "🧑‍🍳",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.cooking",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.cooking"
      },
      {
        emoji: "🍋",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.immune_system",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.immune_system"
      },
      {
        emoji: "💤",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.sleep",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.sleep"
      },
      {
        emoji: "🫶",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.feel_good",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.feel_good"
      },
      {
        emoji: "💬",
        translationKey: "onboarding.encouraging_flow.goal.additional_goal.other",
        trackingName: "onboarding.encouraging_flow.goal.additional_goal.other"
      }
    ],
    nextButtonTranslationKey: "system.general.button.next",
    nextStep: "past-experience-build"
  },
  {
    type: "single_choice",
    id: "past-experience-build",
    eventName: "onboarding.encouraging_flow.build_muscle.past.experience",
    titleTranslationKey: "onboarding.encouraging_flow.build_muscle.past.experience.question",
    options: [
      {
        emoji: "↗️",
        translationKey: "onboarding.encouraging_flow.build_muscle.past.experience.successful",
        trackingName: "onboarding.encouraging_flow.build_muscle.past.experience.successful",
        nextStep: "challenges-build"
      },
      {
        emoji: "➡️",
        translationKey: "onboarding.encouraging_flow.build_muscle.past.experience.unsuccessful",
        trackingName: "onboarding.encouraging_flow.build_muscle.past.experience.unsuccessful",
        nextStep: "challenges-build"
      },
      {
        emoji: "↔️",
        translationKey: "onboarding.encouraging_flow.build_muscle.past.experience.lost_again",
        trackingName: "onboarding.encouraging_flow.build_muscle.past.experience.lost_again",
        nextStep: "challenges-build"
      },
      {
        emoji: "💡",
        translationKey: "onboarding.encouraging_flow.build_muscle.past.experience.first_attempt",
        trackingName: "onboarding.encouraging_flow.build_muscle.past.experience.first_attempt",
        nextStep: "changes-differences"
      },
    ]
  },
  {
    type: "multi_choice",
    id: "challenges-build",
    eventName: "onboarding.encouraging_flow.past.challenges",
    titleTranslationKey: "onboarding.encouraging_flow.past.challenges.question",
    options: [
      {
        emoji: "✨",
        translationKey: "onboarding.encouraging_flow.past.challenges.motivation",
        trackingName: "onboarding.encouraging_flow.past.challenges.motivation"
      },
      {
        emoji: "🥣",
        translationKey: "onboarding.encouraging_flow.past.challenges.quality_foods",
        trackingName: "onboarding.encouraging_flow.past.challenges.quality_foods"
      },
      {
        emoji: "💪",
        translationKey: "onboarding.encouraging_flow.past.challenges.protein",
        trackingName: "onboarding.encouraging_flow.past.challenges.protein"
      },
      {
        emoji: "🥗",
        translationKey: "onboarding.encouraging_flow.past.challenges.food",
        trackingName: "onboarding.encouraging_flow.past.challenges.food"
      },
      {
        emoji: "⏰",
        translationKey: "onboarding.encouraging_flow.past.challenges.busy",
        trackingName: "onboarding.encouraging_flow.past.challenges.busy"
      },
      {
        emoji: "💬",
        translationKey: "onboarding.encouraging_flow.past.challenges.other",
        trackingName: "onboarding.encouraging_flow.past.challenges.other"
      }
    ],
    nextButtonTranslationKey: "system.general.button.next",
    nextStep: "challenges-affirmation-build"
  },
  {
    type: "affirmation",
    id: "challenges-affirmation-build",
    eventName: "onboarding.encouraging_flow.past.challenges_affirmation",
    titleTranslationKey: "onboarding.encouraging_flow.past.challenges_affirmation.title",
    captionTranslationKey: "onboarding.encouraging_flow.build_muscle.past.challenges_affirmation.description",
    imageSize: "LARGE",
    imageUrl: "app/misc/illustrations/welcome_greetings",
    nextButtonTranslationKey: "system.general.button.lets_go",
    nextStep: "gain-causes-build"
  },
  {
    type: "single_choice",
    id: "gain-causes-build",
    eventName: "onboarding.encouraging_flow.build_muscle.past.gain_causes",
    titleTranslationKey: "onboarding.encouraging_flow.build_muscle.past.gain_causes.question",
    options: [
      {
        emoji: "🩹",
        translationKey: "onboarding.encouraging_flow.past.gain_causes.injury",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.injury",
        nextStep: "changes-build"
      },
      {
        emoji: "💼",
        translationKey: "onboarding.encouraging_flow.past.gain_causes.personal_life",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.personal_life",
        nextStep: "changes-build"
      },
      {
        emoji: "🔄",
        translationKey: "onboarding.encouraging_flow.gain_weight.past.gain_causes.metabolism",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.metabolism",
        nextStep: "changes-build"
      },
      {
        emoji: "🤯",
        translationKey: "onboarding.encouraging_flow.past.gain_causes.mental_health",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.mental_health",
        nextStep: "changes-build"
      },
      {
        emoji: "💊",
        translationKey: "onboarding.encouraging_flow.past.gain_causes.medication",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.medication",
        nextStep: "changes-build"
      },
      {
        emoji: "💬",
        translationKey: "onboarding.encouraging_flow.past.gain_causes.other",
        trackingName: "onboarding.encouraging_flow.past.gain_causes.other",
        nextStep: "changes-build"
      }
    ]
  },
  {
    type: "single_choice",
    id: "changes-build",
    eventName: "onboarding.encouraging_flow.past.changes",
    titleTranslationKey: "onboarding.encouraging_flow.build_muscle.past.changes.question",
    optionsLayout: "Grid",
    options: [
      {
        emoji: "👍",
        translationKey: "system.general.button.yes",
        trackingName: "onboarding.encouraging_flow.past.changes.yes",
        nextStep: "changes-differences"
      },
      {
        emoji: "👎",
        translationKey: "system.general.button.no",
        trackingName: "onboarding.encouraging_flow.past.changes.no",
        nextStep: "current-weight"
      },
    ]
  },
];

const somethingChanged: Instructions['screens'] = [
  {
    type: "single_choice",
    id: "changes-differences",
    eventName: "onboarding.encouraging_flow.changes.differences",
    titleTranslationKey: "onboarding.encouraging_flow.changes.differences.question",
    options: [
      {
        emoji: "🧠",
        translationKey: "onboarding.encouraging_flow.changes.differences.mindset",
        trackingName: "onboarding.encouraging_flow.changes.differences.mindset",
        nextStep: "changes-affirmation"
      },
      {
        emoji: "📝",
        translationKey: "onboarding.encouraging_flow.changes.differences.plan",
        trackingName: "onboarding.encouraging_flow.changes.differences.plan",
        nextStep: "changes-affirmation"
      },
      {
        emoji: "🧹",
        translationKey: "onboarding.encouraging_flow.changes.differences.personal_changes",
        trackingName: "onboarding.encouraging_flow.changes.differences.personal_changes",
        nextStep: "changes-affirmation"
      },
      {
        emoji: "🧘",
        translationKey: "onboarding.encouraging_flow.changes.differences.health",
        trackingName: "onboarding.encouraging_flow.changes.differences.health",
        nextStep: "changes-affirmation"
      },
      {
        emoji: "🚀",
        translationKey: "onboarding.encouraging_flow.changes.differences.motivation",
        trackingName: "onboarding.encouraging_flow.changes.differences.motivation",
        nextStep: "changes-affirmation"
      },
      {
        emoji: "💬",
        translationKey: "onboarding.encouraging_flow.changes.differences.other",
        trackingName: "onboarding.encouraging_flow.changes.differences.other",
        nextStep: "changes-affirmation"
      },
    ]
  },
  {
    type: "affirmation",
    id: "changes-affirmation",
    eventName: "onboarding.encouraging_flow.changes.come_back_affirmation",
    titleTranslationKey: "onboarding.encouraging_flow.changes.come_back_affirmation.title",
    captionTranslationKey: "onboarding.encouraging_flow.changes.come_back_affirmation.description",
    imageSize: "LARGE",
    imageUrl: "app/misc/illustrations/progress_success",
    nextButtonTranslationKey: "system.general.button.ive_got_this",
    nextStep: "current-weight"
  },
];

const userData: Instructions['screens'] = [
  {
    type: "current_weight",
    nextStep: "goal-weight",
    nextStepsWithCondition: [
      {
        nextStep: "goal-weight",
        condition: {
          type: "overall_goal",
          branch: "LooseWeight"
        }
      }
    ],
    id: "current-weight",
    eventName: "onboarding.encouraging_flow.personal.current_weight"
  },
  {
    type: "target_weight",
    nextStep: "height",
    nextStepsWithCondition: [
      {
        nextStep: "height",
        condition: {
          type: "overall_goal",
          branch: "LooseWeight"
        }
      }
    ],
    id: "goal-weight",
    eventName: "onboarding.encouraging_flow.personal_goal.goal_weight"
  },
  {
    type: "height",
    nextStep: "birthday",
    id: "height",
    eventName: "onboarding.encouraging_flow.personal.height"
  },
  {
    type: "birthday",
    nextStep: "load-plan",
    id: "birthday",
    eventName: "onboarding.encouraging_flow.personal.birthday"
  },
  {
    type: "loading_personal_plan",
    nextStep: "end",
    id: "load-plan",
    eventName: "onboarding.encouraging_flow.plan.load_plan_1"
  }
];

export const onboardingWelcome: Instructions = {
  start: "age-range",
  screens: [
    ...personalIdentification,
    ...motivationLose,
    ...motivationGain,
    ...motivationBuildMuscle,
    ...somethingChanged,
    ...userData
  ]
}